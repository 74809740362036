/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled from 'styled-components';

import getResolvedColor from '../../utils/getResolvedColor';
import getResolvedBorderColor from '../../utils/getResolvedBorderColor';

const StyledTextInputContainer = styled.div<IStyledTextInputContainerProps>`
  display: flex;
  flex: ${({ flex }) => flex};
  align-items: stretch;
  justify-content: space-between;
  padding: ${({ theme, padding }) => `${theme.spaces[2]} ${theme.spaces[3]}`};
  border: 1px solid ${({ theme, borderColor }) => theme.colors[borderColor]};
  border-color: ${(props) => getResolvedBorderColor(props)};
  margin-bottom: ${({ theme, marginBottom }) => theme.spaces[marginBottom]};
  background-color: ${({ theme }) => theme.colors['white-100']};
  border-radius: ${({ theme }) => theme.radii['std']};
  color: ${(props) => getResolvedColor(props)};
`;

StyledTextInputContainer.defaultProps = {
  marginBottom: 4,
  borderColor: 'grey-200',
  padding: 4,
  flex: '',
  focused: false,
};

export default StyledTextInputContainer;
