/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled, { css } from 'styled-components';

const isDisplayFlex = (display: string): boolean => display === 'flex';

const Box = styled.div<IStyledBoxProps>`
  ${({ position }) => position && `position: ${position}`};
  ${({ theme, top }) => top && `top: ${theme.spaces[top]}`};
  ${({ exactTop }) => exactTop && `top: ${exactTop}`};
  ${({ theme, right }) => right && `right: ${theme.spaces[right]}`};
  ${({ theme, bottom }) => bottom && `bottom: ${theme.spaces[bottom]}`};
  ${({ theme, left }) => left && `left: ${theme.spaces[left]}`};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
  ${({ overflow }) => overflow && `overflow: ${overflow}`};
  ${({ display }) => display && `display: ${display}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  @supports (-moz-appearance: none) {
    ${({ firefoxOnlyHeight }) =>
      firefoxOnlyHeight && `height: ${firefoxOnlyHeight}`};
    ${({ firefoxOnlyMaxHeight }) =>
      firefoxOnlyMaxHeight && `max-height: ${firefoxOnlyMaxHeight}`};
  }
  ${({ height }) => height && `height: ${height}`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ display, flexDirection }) =>
    isDisplayFlex(display) &&
    flexDirection &&
    `flex-direction: ${flexDirection}`};
  ${({ display, flexWrap }) =>
    isDisplayFlex(display) && flexWrap && `flex-wrap: ${flexWrap}`};
  ${({ display, alignItems }) =>
    isDisplayFlex(display) && alignItems && `align-items: ${alignItems}`};
  ${({ display, justifyContent }) =>
    isDisplayFlex(display) &&
    justifyContent &&
    `justify-content: ${justifyContent}`};
  ${({ flexShrink }) => flexShrink && `flex-shrink: ${flexShrink}`};
  ${({ theme, borderRadius }) =>
    borderRadius && `border-radius: ${theme.radii[borderRadius]}`};
  ${({ theme, paddingTop }) =>
    paddingTop && `padding-top: ${theme.spaces[paddingTop]}`};
  ${({ theme, paddingRight }) =>
    paddingRight && `padding-right: ${theme.spaces[paddingRight]}`};
  ${({ theme, paddingBottom }) =>
    paddingBottom && `padding-bottom: ${theme.spaces[paddingBottom]}`};
  ${({ theme, paddingLeft }) =>
    paddingLeft && `padding-left: ${theme.spaces[paddingLeft]}`};
  ${({ theme, paddingY }) =>
    paddingY &&
    `padding-top: ${theme.spaces[paddingY]}; padding-bottom: ${theme.spaces[paddingY]}`};
  ${({ theme, paddingX }) =>
    paddingX &&
    `padding-right: ${theme.spaces[paddingX]}; padding-left: ${theme.spaces[paddingX]}`};
  ${({ theme, paddingRight }) =>
    paddingRight && `padding-right: ${theme.spaces[paddingRight]}`};
  ${({ theme, padding }) => padding && `padding: ${theme.spaces[padding]}`};
  ${({ theme, marginTop }) =>
    marginTop && `margin-top: ${theme.spaces[marginTop]}`};
  ${({ theme, marginRight }) =>
    marginRight && `margin-right: ${theme.spaces[marginRight]}`};
  ${({ theme, marginBottom }) =>
    marginBottom && `margin-bottom: ${theme.spaces[marginBottom]}`};
  ${({ theme, marginLeft }) =>
    marginLeft && `margin-left: ${theme.spaces[marginLeft]}`};
  ${({ theme, margin }) => margin && `margin: ${theme.spaces[margin]}`};
  ${({ theme, marginY }) =>
    marginY &&
    `margin-top: ${theme.spaces[marginY]}; margin-bottom: ${theme.spaces[marginY]}`};
  ${({ theme, marginX }) =>
    marginX &&
    `margin-right: ${theme.spaces[marginX]}; margin-left: ${theme.spaces[marginX]}`};
  ${({ theme, backgroundColor }) =>
    backgroundColor && `background-color: ${theme.colors[backgroundColor]}`};
  ${({ exactBackgroundColor }) =>
    exactBackgroundColor && `background-color: ${exactBackgroundColor}`};
  ${({ theme, color }) => color && `color: ${theme.colors[color]}`};
  ${({ theme, boxShadow }) =>
    boxShadow && `box-shadow: ${theme.shadows[boxShadow]}`};

  ${({ theme, innerXSpace, innerXSpaceMd, innerYSpace }) => css`
    & > *:not(:last-child) {
      ${innerXSpace && `margin-right: ${theme.spaces[innerXSpace]}`};
      ${innerYSpace && `margin-bottom: ${theme.spaces[innerYSpace]}`};

      @media (max-width: 968px) {
        ${innerXSpaceMd && `margin-right: ${theme.spaces[innerXSpaceMd]}`};
      }
    }
  `}

  ${({ theme, marginBottomNotLast }) =>
    marginBottomNotLast &&
    css`
      &:not(:last-child) {
        margin-bottom: ${theme.spaces[marginBottomNotLast]};
      }
    `};
`;

Box.defaultProps = {
  zIndex: null,
  width: null,
  minWidth: null,
  height: null,
  firefoxOnlyHeight: null,
  maxHeight: null,
  firefoxOnlyMaxHeight: null,
  minHeight: null,
  position: null,
  exactTop: null,
  top: null,
  right: null,
  bottom: null,
  left: null,
  overflow: null,
  display: null,
  flex: null,
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexShrink: null,
  borderRadius: null,
  paddingTop: null,
  paddingRight: null,
  paddingBottom: null,
  paddingLeft: null,
  paddingY: null,
  paddingX: null,
  padding: null,
  marginTop: null,
  marginRight: null,
  marginBottom: null,
  marginLeft: null,
  margin: null,
  marginY: null,
  marginX: null,
  marginBottomNotLast: null,
  backgroundColor: null,
  exactBackgroundColor: null,
  color: null,
  boxShadow: null,
  innerXSpace: null,
  innerXSpaceMd: null,
  innerYSpace: null,
};

export default Box;
