/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import StyledAuthFooter from './style';

import Hr from 'atoms/Hr';
import Link from 'atoms/Link';
import Typography from 'atoms/Typography';

const AuthFooter = (): JSX.Element => (
  <>
    <Hr />

    <StyledAuthFooter>
      <div>
        <Typography as="small">
          <strong>Copyright &copy; 2018-2021</strong>
        </Typography>
        &nbsp;
        <Link
          color="blue-600"
          fontSize="sm"
          href="https://sip3.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>SIP3</b>.IO, Corp.
        </Link>
        &nbsp;
        <Typography as="small">All rights reserved.</Typography>
      </div>

      <Typography as="small">
        <strong>Version</strong>:{' '}
        {process.env.npm_package_version || 'not provided'}
      </Typography>
    </StyledAuthFooter>
  </>
);

export default AuthFooter;
