/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const DEFAULT_APP_NAME = 'SIP3.IO';

export const API_PREFIX: string = process.env.API_PREFIX || '/api';

export const UTC_OFFSET = process.env.UTC_OFFSET || '';

export const NAV_ITEMS: string[] = [
  '/search/simple',
  '/search/advanced?entry-point&new',
  '=',
  '/signout?url=&title=Sign Out',
];

export const NOT_AUTHENTICATED = 'Not authenticated';

/*
  Restrict sip user format
  Valid values are:
  '' - any characters
  'MSISDN' - digits, '.', '*'
 */

export const SIP_USER_IDENTITY: string =
  typeof process.env.SIP_USER_IDENTITY === 'string'
    ? process.env.SIP_USER_IDENTITY
    : 'MSISDN';

export const SESSION_STATE_COLOR_MAPPING = {
  answered: '#66BB6A',
  canceled: '#FF8A65',
  failed: '#F93841',
  processing: '#7986CB',
  unauthorized: '#7986CB',
  unknown: '#092C40',
  registered: '#F6C24A',
  redirected: '#F6C24A',
};

export const SIP_RESPONSE_CODE: { [key: string]: string } = {
  // 1xx—Provisional Responses
  '100': 'Trying',
  '180': 'Ringing',
  '181': 'Call is Being Forwarded',
  '182': 'Queued',
  '183': 'Session Progress',
  '199': 'Early Dialog Terminated',
  // 2xx—Successful Responses	Edit
  '200': 'OK',
  '202': 'Accepted',
  '204': 'No Notification',
  // 3xx-other responses	Edit
  '300': 'Multiple Choices',
  '301': 'Moved Permanently',
  '302': 'Moved Temporarily',
  '305': 'Use Proxy',
  '380': 'Alternative Service',
  // 4xx—Client Failure Responses	Edit
  '400': 'Bad Request',
  '401': 'Unauthorized',
  '402': 'Payment Required',
  '403': 'Forbidden',
  '404': 'Not Found',
  '405': 'Method Not Allowed',
  '406': 'Not Acceptable',
  '407': 'Proxy Authentication Required',
  '408': 'Request Timeout',
  '409': 'Conflict',
  '410': 'Gone',
  '411': 'Length Required',
  '412': 'Conditional Request Failed',
  '413': 'Request Entity Too Large',
  '414': 'Request-URI Too Long',
  '415': 'Unsupported Media Type',
  '416': 'Unsupported URI Scheme',
  '417': 'Unknown Resource-Priority',
  '420': 'Bad Extension',
  '421': 'Extension Required',
  '422': 'Session Interval Too Small',
  '423': 'Interval Too Brief',
  '424': 'Bad Location Information',
  '428': 'Use Identity Header',
  '429': 'Provide Referrer Identity',
  '433': 'Anonymity Disallowed',
  '436': 'Bad Identity-Info',
  '437': 'Unsupported Certificate',
  '438': 'Invalid Identity Header',
  '439': 'First Hop Lacks Outbound Support',
  '440': 'Max-Breadth Exceeded',
  '469': 'Bad Info Package',
  '470': 'Consent Needed',
  '480': 'Temporarily Unavailable',
  '481': 'Call/Transaction Does Not Exist',
  '482': 'Loop Detected',
  '483': 'Too Many Hops',
  '484': 'Address Incomplete',
  '485': 'Ambiguous',
  '486': 'Busy Here',
  '487': 'Request Terminated',
  '488': 'Not Acceptable Here',
  '489': 'Bad Event',
  '491': 'Request Pending',
  '493': 'Undecipherable',
  '494': 'Security Agreement Required',
  // 5xx—Server Failure Responses	Edit
  '500': 'Server Internal Error',
  '501': 'Not Implemented',
  '502': 'Bad Gateway',
  '503': 'Service Unavailable',
  '504': 'Server Time-out',
  '505': 'Version Not Supported',
  '513': 'Message Too Large',
  '580': 'Precondition Failure',
  // 6xx—Global Failure Responses	Edit
  '600': 'Busy Everywhere',
  '603': 'Decline',
  '604': 'Does Not Exist Anywhere',
  '606': 'Not Acceptable',
  '607': 'Unwanted',
};
