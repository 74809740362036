/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import StyledHeader from './style';

import AppStateInteractor from 'interactors/AppStateInteractor';
import Button from 'atoms/Button';
import Typography from 'atoms/Typography';

const Header = ({
  toggleSidebar,
  isSidebarCollapsed,
}: IHeaderProps): JSX.Element => {
  const appStateInteractorInstance = AppStateInteractor.getInstance();
  const appStateInteractorModel = appStateInteractorInstance.getModel();
  const [pageTitle, setPageTitle] = useState(
    appStateInteractorModel.currentRoute?.title || '404 error'
  );

  const onASIUpdated = (appStateInteractorModel: IAppState) => {
    setPageTitle(appStateInteractorModel.currentRoute?.title || '404 error');
  };

  useEffect(() => {
    appStateInteractorInstance.subscribeToUpdates(onASIUpdated);
    onASIUpdated(appStateInteractorInstance.getModel());

    return () => {
      appStateInteractorInstance.unsubscribeFromUpdates(onASIUpdated);
    };
  }, []);

  return (
    <StyledHeader>
      <Button styled={false} color="black-900" onClick={toggleSidebar}>
        {isSidebarCollapsed ? <ChevronRightRoundedIcon /> : <MenuIcon />}
      </Button>

      <Typography as="span">{pageTitle}</Typography>
    </StyledHeader>
  );
};

export default Header;
