/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { NAV_ITEMS } from 'src/constants';

const parseMenuURI = (menuURI: string): IRoute => {
  if (menuURI === '-') {
    return { path: '-', title: '', isNew: false, isBeta: false };
  }

  if (menuURI === '=') {
    return { path: '=', title: '', isNew: false, isBeta: false };
  }

  const [path, query] = Array.prototype.slice.call(
    menuURI.match(/^(.+?)(?:\?(.*))?$/),
    1
  );

  const options = (query || '')
    .split('&')
    .reduce((acc: { 'entry-point'?: string; url?: string }, option: string) => {
      return option.match(/^([A-Za-z-]+)(?:=(.*))?$/)
        ? {
            ...acc,
            [decodeURIComponent(RegExp.$1)]: decodeURIComponent(RegExp.$2),
          }
        : acc;
    }, {});

  const title =
    options.title ||
    path
      .split('/')
      .slice(1)
      .map((p) => p[0].toUpperCase() + p.slice(1))
      .reverse()
      .join(' ');

  const icon: string =
    options.icon || path.split('/').slice(1).reverse().join('-') || '';

  const isNew: boolean = 'new' in options;
  const isBeta: boolean = 'beta' in options;
  const isEntryPoint: boolean = 'entry-point' in options;

  return { path, title, icon, isNew, isEntryPoint, isBeta };
};

const navRoutes = NAV_ITEMS.map(parseMenuURI);

export default navRoutes;
