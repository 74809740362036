/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const legacyColors = {
  'primary-1': '#F93841',
  'primary-2': '#5AADDF',
  'primary-3': '#25507F',
  'primary-4': '#092C40',
  'secondary-1': '#F6C24A',
  'secondary-2': '#D9D9D9',
  'secondary-3': '#7986CB',
  'secondary-4': '#FF8A65',
  'secondary-5': '#66BB6A',
  'secondary-6': '#B2EBF2',
  'secondary-7': '#BBBBBB',
  'background-1': '#D7E6EB',
  'background-2': '#5AADDF',
  'background-3': '#FFFFFF',
  'text-color': '#FF8A65',
  navy: '#25507F',
  teal: '#39CCCC',
  olive: '#3D9970',
  lime: '#01FF70',
  orange: '#FF851B',
  fuchsia: '#F012BE',
  purple: '#605ca8',
  maroon: '#D81B60',
  black: '#111',
  'gray-x-light': '#d2d6de',
  dark: '#FF8A65',
  light: '#B2EBF2',
  success: '#66BB6A',
};

const colors: IThemeColors = {
  transparent: 'rgba(0,0,0,0)',
  // tailwind-like notation
  // white colors
  'white-100': '#fff',
  // black-colors
  'black-900': '#000',
  // blue colors
  'blue-100': '#d7e6eb',
  'blue-200': '#d1e0e5',
  'blue-600': '#5aaddf',
  'blue-700': '#268ac6',
  'blue-800': '#26507f',
  'blue-900': '#092c40',
  // grey colors
  'grey-100': '#f2f2f2',
  'grey-150': '#e2e1e1',
  'grey-200': '#ccc',
  'grey-300': '#bbbbbb',
  'grey-500': '#6a6a6a',
  // red colors
  'red-500': '#f93841',
  // yellow colors
  'yellow-500': '#ffc107',
  // green colors
  'green-500': '#66bb6a',
};

export default colors;
