/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled, { createGlobalStyle } from 'styled-components';
import getStylesForScrollbar from 'utils/getStylesForScrollbar';

const StyledAuthScreen = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: ${({ theme }) => theme.colors['blue-100']};
`;

const BodyStyle = createGlobalStyle<{ theme: ITheme }>`
  body {
    ${getStylesForScrollbar({
      scrollbarColor: 'grey-100',
      backgroundColor: 'blue-100',
      isAlwaysVisible: true,
    })};
  }
`;

export { BodyStyle };
export default StyledAuthScreen;
