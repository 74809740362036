/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled from 'styled-components';

const Link = styled.a<IStyledLinkProps>`
  color: ${({ theme, color }) => theme.colors[color]};
  font-size: ${({ theme, fontSize }) => theme.fontSizes[fontSize]};
  ${({ display }) => display && `display: ${display};`}
  outline: none;
  pointer-events: ${({ allowPointerEvents }) =>
    allowPointerEvents ? 'auto' : 'none'};
  text-align: ${(p) => p.textAlign};
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    ${({ theme, allowPointerEvents, hoverEffect }) =>
      allowPointerEvents &&
      hoverEffect &&
      `color: ${theme.colors['blue-800']}`};
    ${({ hoverEffect, allowPointerEvents, underlineOnHover }) =>
      allowPointerEvents &&
      hoverEffect &&
      underlineOnHover &&
      `text-decoration: underline`};
  }
`;

Link.defaultProps = {
  color: 'blue-600',
  fontSize: 'base',
  hoverEffect: true,
  textAlign: 'left',
  underlineOnHover: true,
  allowPointerEvents: true,
};

export default Link;
