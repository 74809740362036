/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const spaces: IThemeSpaces = {
  auto: 'auto',
  '-4': '-0.75rem',
  '-3': '-0.5rem',
  '-2': '-0.25rem',
  '-1': '-0.125rem',
  '0': '0',
  '1': '0.125rem',
  '2': '0.25rem',
  '3': '0.5rem',
  '4': '0.75rem',
  '5': '1rem',
  '6': '1.25rem',
  '7': '1.5rem',
  '8': '1.75rem',
  '9': '2rem',
  '10': '2.25rem',
  '11': '2.75rem',
  '12': '3rem',
  '15': '4rem',
  '40': '10rem',
};

export default spaces;
