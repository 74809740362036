/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled from 'styled-components';

const StyledSidebar = styled.aside<{ isSidebarCollapsed: boolean }>`
  position: relative;
  z-index: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.gridGutters[1]} ${theme.gridGutters[2]}`};
  background: ${({ theme }) => theme.colors['white-100']};
  box-shadow: 10px 0 20px 0 rgb(112, 144, 176, 0.2);

  ${({ isSidebarCollapsed }) => !isSidebarCollapsed && `min-width: 210px`};
`;

const StyledNav = styled.nav`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledUl = styled.ul`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  list-style: none;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spaces[5]};
  }
`;

export { StyledSidebar, StyledNav, StyledUl };
