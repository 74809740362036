/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';

import { StyledSpace } from './style';

import Hr from 'atoms/Hr';
import Link from 'atoms/Link';
import Typography from 'atoms/Typography';
import Button from 'atoms/Button';
import Box from 'atoms/Box';

const NavItem = ({
  route,
  currentRoute,
  onPressNavItem,
  isSidebarCollapsed,
}: INavItemProps): JSX.Element => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (route.path === '' || route.path === '-') {
      return;
    }

    if (onPressNavItem && onPressNavItem(route)) {
      event.preventDefault();
    }
  };

  if (route.path === '=') {
    return <StyledSpace />;
  }

  if (route.path === '-') {
    return <Hr />;
  }

  const active: boolean = route === currentRoute;
  const path: string | null = route.path;

  const title = route.title;
  const isNew = !!route.isNew;
  const isBeta = !!route.isBeta;

  let Icon;

  if (route.icon === 'signout') {
    Icon = <ExitToAppIcon />;
  } else if (route.icon === 'simple-search') {
    Icon = (
      <Box width="30px" minWidth="30px" height="30px" minHeight="30px">
        <SearchIcon style={{ width: '30px', height: '30px' }} />
      </Box>
    );
  } else if (route.icon === 'advanced-search') {
    Icon = (
      <Box
        className="Icon"
        width="30px"
        minWidth="30px"
        height="30px"
        minHeight="30px"
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.375 17.5H18.3875L18.0375 17.1625C19.2625 15.7375 20 13.8875 20 11.875C20 7.3875 16.3625 3.75 11.875 3.75C7.3875 3.75 3.75 7.3875 3.75 11.875C3.75 16.3625 7.3875 20 11.875 20C13.8875 20 15.7375 19.2625 17.1625 18.0375L17.5 18.3875V19.375L23.75 25.6125L25.6125 23.75L19.375 17.5V17.5ZM11.875 17.5C8.7625 17.5 6.25 14.9875 6.25 11.875C6.25 8.7625 8.7625 6.25 11.875 6.25C14.9875 6.25 17.5 8.7625 17.5 11.875C17.5 14.9875 14.9875 17.5 11.875 17.5Z"
            fill="currentColor"
          />
          <path
            d="M16 12.5714H12.5714V16H11.4286V12.5714H8V11.4286H11.4286V8H12.5714V11.4286H16V12.5714Z"
            fill="currentColor"
          />
        </svg>
      </Box>
    );
  }

  return (
    <li>
      <Link
        href={path ? '#' + path : '#'}
        onClick={handleClick}
        color={active ? 'blue-600' : 'black-900'}
        hoverEffect={!active}
        underlineOnHover={false}
        allowPointerEvents={!active}
        display="block"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isSidebarCollapsed ? 'center' : 'space-between'}
          innerXSpace="3"
          innerXSpaceMd="2"
        >
          {Icon}

          {isSidebarCollapsed ? null : (
            <Typography as="span" className="flex-1" fontSizeMd="sm">
              {title}
            </Typography>
          )}

          {!isSidebarCollapsed && isNew ? (
            <Button
              as="div"
              hoverEffect={false}
              backgroundColor="red-500"
              fontSize="xs"
              fontSizeMd="2xs"
              squared
              padding="2"
            >
              New
            </Button>
          ) : null}

          {!isSidebarCollapsed && isBeta ? (
            <Button
              as="div"
              hoverEffect={false}
              backgroundColor="yellow-500"
              fontSize="xs"
              fontSizeMd="2xs"
              squared
              padding="2"
            >
              βeta
            </Button>
          ) : null}
        </Box>
      </Link>
    </li>
  );
};

export default NavItem;
