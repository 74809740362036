/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled from 'styled-components';

const StyledHr = styled.hr<IStyledHrProps>`
  display: block;
  width: 100%;
  height: 1px;
  padding: 0;
  border: 0;
  border-top: 1px solid ${({ theme }) => theme.colors['grey-200']};
  margin-top: ${({ theme, space }) => theme.spaces[space]};
  margin-bottom: ${({ theme, space }) => theme.spaces[space]};
`;

StyledHr.defaultProps = {
  space: 5,
};

export default StyledHr;
