/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import colors from './colors';
import fontSizes from './fontSizes';
import fontWeights from './fontWeights';
import elementsToFontSizesMapping from './elementsToFontSizesMapping';
import spaces from './spaces';
import gridGutters from './gridGutters';
import shadows from './shadows';
import radii from './radii';

const theme: ITheme = {
  colors,
  fontSizes,
  fontWeights,
  elementsToFontSizesMapping,
  gridGutters,
  spaces,
  shadows,
  radii,
};

export default theme;
