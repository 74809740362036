/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import BaseObservable from 'interactors/BaseObservable';
import allNavRoutes from 'utils/routes/allNavRoutes';
import { IS_DEVELOPMENT } from 'constants/buildModes';

function urlIncludes(routeUrl: string, currentUrl: string): boolean {
  return (
    routeUrl.startsWith(currentUrl) &&
    (routeUrl[currentUrl.length] === '/' ||
      routeUrl[currentUrl.length - 1] === '/')
  );
}

function getRouteByUrl(url: string): IRoute | null {
  for (const route of allNavRoutes) {
    if (route.path === url) {
      return route;
    }
  }

  if (url === '' || url === '/') {
    // find entry point
    const route = allNavRoutes.find((r) => !!r.isEntryPoint);
    if (route) {
      return route;
    }
  }

  // find by common start
  for (const route of allNavRoutes) {
    if (route.path && urlIncludes(route.path, url)) {
      return route;
    }
  }

  return null;
}

export default class AppStateInteractor extends BaseObservable<IAppState> {
  private constructor() {
    super({
      millis: [new Date().valueOf() - 1000 * 60 * 60, new Date().valueOf()], // last 1 hour
      currentRoute: null,
    });
    window.addEventListener('hashchange', this._onHashChanged, false);
    this._onHashChanged();
  }

  private _onHashChanged = async () => {
    let hash: string = window.location.hash || '';
    if (hash[0] === '#') hash = hash.slice(1);
    const currentRoute: IRoute | null = getRouteByUrl(hash);
    if (IS_DEVELOPMENT) {
      console.log('_onHashChanged', currentRoute);
    }
    this._updateModel({ currentRoute });

    if (currentRoute === null && hash[0] === '/') {
      try {
        await import(`routes/${hash.slice(1)}`); //TODO: wtf?
        // page exists
        this._updateModel({
          currentRoute: {
            path: hash,
            title: hash
              .split('/')
              .slice(1)
              .map((p) => p[0].toUpperCase() + p.slice(1))
              .reverse()
              .join(' '),
          },
        });
      } catch (err) {
        // not found: 404
        this._updateModel({ currentRoute: null });
      }
    }
  };

  private static _instance: AppStateInteractor | null;

  public static getInstance(): AppStateInteractor {
    if (!this._instance) {
      this._instance = new AppStateInteractor();
    }
    return this._instance;
  }

  public static getModel(): IAppState {
    return this.getInstance().getModel();
  }

  public static subscribeToUpdates(
    callback: (m: IAppState) => unknown
  ): IDisposable {
    return this.getInstance().subscribeToUpdates(callback);
  }

  public static unsubscribeFromUpdates(
    callback: (m: IAppState) => unknown
  ): void {
    this.getInstance().unsubscribeFromUpdates(callback);
  }
}
