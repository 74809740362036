/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import fontSizes from 'theme/fontSizes';

const elementsToFontSizesMapping: IThemeElementsToFontSizesMapping = {
  h1: fontSizes['6xl'],
  h2: fontSizes['4xl'],
  h3: fontSizes['3xl'],
  h4: fontSizes['2xl'],
  h5: fontSizes['xl'],
  h6: fontSizes['lg'],
  p: fontSizes['base'],
  span: fontSizes['base'],
  small: fontSizes['sm'],
};

export default elementsToFontSizesMapping;
