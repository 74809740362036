/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { PureComponent } from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import StyledApp, { GlobalStyle } from './style';

import theme from 'theme/index';

import AuthScreen from 'components/AuthScreen';
import MainLayout from 'components/MainLayout';
import Popups from 'components/Popups';

import PopupsInteractor from 'interactors/PopupsInteractor';

import AuthenticationInteractor from 'interactors/AuthenticationInteractor';
import AppStateInteractor from 'interactors/AppStateInteractor';
import { DEFAULT_APP_NAME } from 'src/constants';
import { IS_DEVELOPMENT } from 'constants/buildModes';
import DebugGrid from 'components/DebugGrid';

export default class App extends PureComponent {
  public override readonly state: {
    popupsList: IPopup[];
    authenticated: boolean;
    currentRoute: IRoute | null;
    authError: string | null;
    appName: string;
    page: () => JSX.Element | null;
  };

  public constructor(props: Record<string, never>) {
    super(props);

    const auth: IAuthentication = AuthenticationInteractor.getModel();
    const popups: IPopups = PopupsInteractor.getModel();
    const appState: IAppState = AppStateInteractor.getModel();

    const hostname: string = window.location.hostname;
    const appName: string = /(\w+)\.sip3\.io/.test(hostname)
      ? RegExp.$1
      : DEFAULT_APP_NAME;
    document.title = appName;

    this.state = {
      ...popups,
      currentRoute: appState.currentRoute,
      page: null,
      authenticated: auth.authenticated,
      authError: auth.error,
      appName,
    };

    this.onRouteUpdated(appState.currentRoute).then();
  }

  private _isMounted = false;

  public override componentDidMount(): void {
    this._isMounted = true;
    AuthenticationInteractor.subscribeToUpdates(this.onDepsUpdated);
    PopupsInteractor.subscribeToUpdates(this.onDepsUpdated);
    AppStateInteractor.subscribeToUpdates(this.onDepsUpdated);
    this.onDepsUpdated();
    document.body.addEventListener('keydown', this.onGlobalKeyDown, false);
  }

  public override componentWillUnmount(): void {
    this._isMounted = false;
    AppStateInteractor.unsubscribeFromUpdates(this.onDepsUpdated);
    PopupsInteractor.unsubscribeFromUpdates(this.onDepsUpdated);
    AuthenticationInteractor.unsubscribeFromUpdates(this.onDepsUpdated);
    document.body.removeEventListener('keydown', this.onGlobalKeyDown, false);
  }

  private onDepsUpdated = () => {
    const auth: IAuthentication = AuthenticationInteractor.getModel();
    const popups: IPopups = PopupsInteractor.getModel();
    const appState: IAppState = AppStateInteractor.getModel();

    this.setState({
      ...popups,
      currentRoute: appState.currentRoute,
      authenticated: auth.authenticated,
      authError: auth.error,
    });

    this.onRouteUpdated(appState.currentRoute);
  };

  private async onRouteUpdated(route: IRoute | null) {
    let page = null;

    try {
      // when we got message to navigate to other page
      // first we load it (keeping previous page on the screen)
      // and only after loaded we replace the page with new one
      //
      // this timer will execute if current page is taking all resources, e.g. network resources
      // preventing a new page to be loaded. So, only if times executed, clear the state.page
      let timerId: number | null = window.setTimeout(() => {
        timerId = null;
        const appState: IAppState = AppStateInteractor.getModel();
        if (route.path === appState.currentRoute?.path) {
          // route didn't changed
          this.setState({ page: null });
        }
      }, 700);

      if (route) {
        page = (await import(`../../routes${route.path}`)).default;
      }

      window.clearTimeout(timerId);
      timerId = null;
    } catch {}

    if (this._isMounted) {
      this.setState({ page });
    } else {
      this.state.page = page;
    }
  }

  private onGlobalKeyDown = (event: KeyboardEvent): void => {
    if (event.code === 'Escape') {
      PopupsInteractor.closeLastPopup();
    }
  };

  private closePopup = (popupId: string): void => {
    PopupsInteractor.closePopup(popupId);
  };

  private selectPopup = (popupId: string): void => {
    PopupsInteractor.selectPopup(popupId);
  };

  private onPressNavItem = (route: IRoute): boolean => {
    if (route.path === '/signout') {
      AuthenticationInteractor.signOut().then();
      return false;
    }

    return false;
  };

  public render(): JSX.Element {
    const { authenticated, currentRoute, appName, page, popupsList } =
      this.state;

    return (
      <StyleSheetManager disableVendorPrefixes={IS_DEVELOPMENT}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />

          <StyledApp>
            {authenticated ? (
              <>
                <MainLayout
                  currentRoute={currentRoute}
                  appName={appName}
                  page={page}
                  onPressNavItem={this.onPressNavItem}
                />

                <Popups
                  popupsList={popupsList}
                  closePopup={this.closePopup}
                  selectPopup={this.selectPopup}
                />
              </>
            ) : (
              <AuthScreen />
            )}

            {IS_DEVELOPMENT ? <DebugGrid render={false} /> : null}
          </StyledApp>
        </ThemeProvider>
      </StyleSheetManager>
    );
  }
}
