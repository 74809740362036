/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import BaseObservable from 'interactors/BaseObservable';
import AppStateInteractor from 'interactors/AppStateInteractor';
import PositionResolver from './PositionResolver';
import { IS_DEVELOPMENT } from 'constants/buildModes';

export default class PopupsInteractor extends BaseObservable<IPopups> {
  private _appStateInteractor: AppStateInteractor;
  private _currentRoute: IRoute | null;
  private _positionResolver: PositionResolver = new PositionResolver();
  private _popupCounter = 1;

  private constructor() {
    super({
      popupsList: [],
    });

    this._appStateInteractor = AppStateInteractor.getInstance();
    this._currentRoute = this._appStateInteractor.getModel().currentRoute;
    this._appStateInteractor.subscribeToUpdates(this._onDepsUpdated);
  }

  public openPopup(openPopup: IOpenPopup): void {
    let { popupsList } = this._model;
    const popupId: string = openPopup.id || `popup_${this._popupCounter++}`;

    const p: IPopup | undefined = popupsList.find((p) => p.id === popupId);
    if (p) {
      this.selectPopup(p.id);
      return;
    }

    const { positionX, positionY } =
      this._positionResolver.reservePosition(popupId);

    const maxZIndex = popupsList.reduce((n, p) => Math.max(p.zIndex, n), 0);

    const popup: IPopup = {
      id: popupId,
      title: openPopup.title,
      content: openPopup.content,
      parentId: openPopup.parentId,
      positionX,
      positionY,
      size: openPopup.size,
      zIndex: maxZIndex + 1,
      className: openPopup.className,
    };

    popupsList = [...popupsList, popup];
    this._updateModel({ popupsList });
  }

  public closePopup(popupId: string): void {
    let { popupsList } = this._model;

    const toRemove = popupsList.filter(
      (p) => p.id === popupId || p.parentId === popupId
    );

    if (!toRemove.length) {
      if (IS_DEVELOPMENT) {
        console.log(`closePopup: Popup with id ${popupId} not found`);
      }
      return;
    }

    toRemove.forEach((p) => this._positionResolver.freePosition(p.id));

    popupsList = popupsList.filter(
      (p) => p.id !== popupId && p.parentId !== popupId
    ); // popup and its children are closed

    this._updateModel({ popupsList });
  }

  public selectPopup(popupId: string): void {
    let { popupsList } = this._model;

    const p = popupsList.find((p) => p.id === popupId);
    if (!p) {
      return;
    }

    const popupById: { [popupId: string]: IPopup } = {};
    popupsList.forEach((p) => (popupById[p.id] = p)); // for quick search of popup

    let popupIds: string[] = popupsList.map((p) => p.id);
    popupIds.sort((id1, id2) => popupById[id1].zIndex - popupById[id2].zIndex); // all the ids of popups ordered by zIndex

    // move selected popup id to the end of list
    const idx = popupIds.indexOf(popupId);
    popupIds.splice(idx, 1);
    popupIds.push(popupId);

    // move all children ids to the end of list
    const childrenIds = popupIds.filter(
      (cid) => popupById[cid].parentId === popupId
    ); // ids of children with correct order by zIndex
    popupIds = popupIds
      .filter((cid) => !childrenIds.includes(cid))
      .concat(childrenIds);

    // due to chrome issue of canceling onClick event when dom is modified we cannot update order of popups, but only zIndexes
    popupsList = popupsList.map((p) => ({
      ...p,
      zIndex: popupIds.indexOf(p.id) + 1,
    }));

    this._updateModel({ popupsList });
  }

  public closeLastPopup(): void {
    if (this._model.popupsList.length) {
      this.closePopup(
        this._model.popupsList[this._model.popupsList.length - 1].id
      );
    }
  }

  private _onDepsUpdated = () => {
    const asi = this._appStateInteractor.getModel();
    if (this._currentRoute !== asi.currentRoute) {
      this._currentRoute = asi.currentRoute;
      this._updateModel({ popupsList: [] }); // close all when navigating
      this._positionResolver.freeAll();
    }
  };

  // singleton
  private static _instance: PopupsInteractor | null = null;

  public static getInstance(): PopupsInteractor {
    if (!this._instance) {
      this._instance = new PopupsInteractor();
    }
    return this._instance;
  }

  public static openPopup(openPopup: IOpenPopup): void {
    this.getInstance().openPopup(openPopup);
  }

  public static closeLastPopup(): void {
    this.getInstance().closeLastPopup();
  }

  public static closePopup(id: string): void {
    this.getInstance().closePopup(id);
  }

  public static selectPopup(id: string): void {
    this.getInstance().selectPopup(id);
  }

  public static getModel(): IPopups {
    return this.getInstance().getModel();
  }

  public static subscribeToUpdates(
    callback: (m: IPopups) => unknown
  ): IDisposable {
    return this.getInstance().subscribeToUpdates(callback);
  }

  public static unsubscribeFromUpdates(
    callback: (m: IPopups) => unknown
  ): void {
    this.getInstance().unsubscribeFromUpdates(callback);
  }
}
