/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createStyles, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      paddingRight: '8px',
      paddingLeft: '8px',
      allowPointerEvents: 'none',
      userSelect: 'none',
    },
    gridContainer: {
      flex: 1,
      width: '100%',
      height: '100%',
      marginTop: '0',
      marginBottom: '0',
    },
    gridItem: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
    item: {
      width: '100%',
      height: '100%',
      background: 'rgba(255, 0, 0, 0.10)',
    },
  })
);

const DebugGrid = ({ render = true }: { render: boolean }): JSX.Element => {
  const classes = useStyles();

  if (!render) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Grid container spacing={2} className={classes.gridContainer}>
        {new Array(12).fill(1).map((el, idx) => (
          <Grid item key={idx} xs={1} className={classes.gridItem}>
            <div className={classes.item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default DebugGrid;
