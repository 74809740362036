/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useEffect, useRef, useState } from 'react';

import StyledSignInFormContainer from './style';

import { FORGOT_PASSWORD_LINK } from './constants';

import Typography from 'atoms/Typography';
import Link from 'atoms/Link';
import Button from 'atoms/Button';
import TextInput from 'atoms/inputs/TextInput';

import sip3InCircleLogo from 'assets/svg/logo/sip3-in-circle.svg';
import Box from 'atoms/Box';

const SignInForm = ({
  isLoading,
  error,
  onSignIn,
}: ISignInFormProps): JSX.Element => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const usernameInput = useRef(null);

  useEffect(() => {
    const $usernameInput = usernameInput.current;

    $usernameInput?.focus();
  }, []);

  const handleSignInClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onSignIn(username, password);

    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <StyledSignInFormContainer>
      <Box marginTop="3">
        <img src={sip3InCircleLogo} alt="sip3 logo" width="125" height="125" />
      </Box>

      <Box
        as="form"
        display="flex"
        flex="2"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        innerYSpace="4"
        role="form"
        action="index.html"
      >
        <Typography as="h4" marginBottom="6">
          Sign in to your account
        </Typography>

        {error ? (
          <Typography as="p" fontSize="xs" marginBottom="4" color="red-500">
            {error}
          </Typography>
        ) : null}

        <TextInput
          type="text"
          innerRef={usernameInput}
          value={username}
          placeholder="Username"
          required={true}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setUsername(event.currentTarget.value.toLowerCase())
          }
          autoFocus={true}
          autoComplete="username"
        />

        <TextInput
          type="password"
          value={password}
          placeholder="Password"
          required={true}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setPassword(event.currentTarget.value)
          }
          autoComplete="current-password"
        />

        <Link href={FORGOT_PASSWORD_LINK} fontSize="sm" className="ml-auto">
          Forgot password?
        </Link>

        <Button
          type="submit"
          onClick={handleSignInClick}
          showLoader={isLoading}
          disabled={isLoading || username === '' || password === ''}
        >
          Sign in
        </Button>

        <Link href="#/signup" fontSize="sm">
          Sign up
        </Link>
      </Box>
    </StyledSignInFormContainer>
  );
};

export default SignInForm;
