/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled, { css } from 'styled-components';
import hexToRgba from 'utils/hexToRgba';

const getResolvedBackgroundColor = ({
  theme,
  disabled,
  styled,
  backgroundColor,
}: IStyledButtonProps): string => {
  if (styled) {
    if (disabled) {
      if (backgroundColor === 'transparent') {
        return theme.colors[backgroundColor];
      }

      return hexToRgba(theme.colors[backgroundColor], 0.4);
    }

    return theme.colors[backgroundColor];
  }

  return theme.colors['transparent'];
};

const getResolvedCursor = ({ disabled }: IStyledButtonProps): string => {
  if (disabled) return 'not-allowed';

  return 'pointer';
};

const getResolvedColor = ({
  theme,
  disabled,
  styled,
  color,
}: IStyledButtonProps): string => {
  const resolvedColor = styled ? color : 'black-900';

  if (disabled) {
    return hexToRgba(theme.colors[resolvedColor], 0.5);
  }

  return theme.colors[resolvedColor];
};

const getResolvedFocusRingColor = ({
  focusEffect,
  styled,
  disabled,
  theme,
  backgroundColor,
}: IStyledButtonProps): string => {
  if (disabled && !focusEffect) {
    return '';
  }

  if (styled) {
    if (backgroundColor === 'transparent') {
      return hexToRgba(theme.colors['blue-600'], 0.4);
    }

    return hexToRgba(theme.colors[backgroundColor], 0.4);
  }

  return hexToRgba(theme.colors['blue-600'], 0.4);
};

const Button = styled.button<IStyledButtonProps>`
  position: relative;
  display: flex;
  overflow: hidden;
  width: auto;
  max-width: fit-content;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0;
  background-color: ${(props) => getResolvedBackgroundColor(props)};
  border-radius: ${({ theme, borderRadius }) => theme.radii[borderRadius]};
  color: ${(props) => getResolvedColor(props)};
  cursor: ${(props) => getResolvedCursor(props)};
  font-size: ${({ theme, fontSize }) => theme.fontSizes[fontSize]};
  ${({ clickable }) => !clickable && 'pointer-events: auto;'}
  outline: none;
  pointer-events: ${({ allowPointerEvents }) =>
    allowPointerEvents ? 'auto' : 'none'};
  text-align: center;
  user-select: none;
  vertical-align: baseline;
  white-space: nowrap;

  ${({ theme, borderTopRightRadius }) =>
    borderTopRightRadius &&
    `border-top-right-radius: ${theme.radii[borderTopRightRadius]}`};
  ${({ theme, borderBottomRightRadius }) =>
    borderBottomRightRadius &&
    `border-top-bottom-radius: ${theme.radii[borderBottomRightRadius]}`};
  ${({ theme, borderBottomLeftRadius }) =>
    borderBottomLeftRadius &&
    `border-bottom-left-radius: ${theme.radii[borderBottomLeftRadius]}`};
  ${({ theme, borderTopLeftRadius }) =>
    borderTopLeftRadius &&
    `border-top-left-radius: ${theme.radii[borderTopLeftRadius]}`};

  ${({ theme, padding, squared }) =>
    squared
      ? `padding: ${theme.spaces[padding]}`
      : `padding: calc(${theme.spaces[padding]} / 2) ${theme.spaces[padding]}`};
  ${({ theme, paddingY }) =>
    paddingY &&
    `padding-top: ${theme.spaces[paddingY]}; padding-bottom: ${theme.spaces[paddingY]};`};
  ${({ theme, paddingX }) =>
    paddingX &&
    `padding-left: ${theme.spaces[paddingX]}; padding-right: ${theme.spaces[paddingX]};`};

  @media (max-width: 968px) {
    font-size: ${({ theme, fontSizeMd }) => theme.fontSizes[fontSizeMd]};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: ${(props) =>
      getResolvedFocusRingColor(props) &&
      `0 0 0 3px ${getResolvedFocusRingColor(props)}`};
  }

  ${({ showLoader }) =>
    showLoader &&
    css`
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        width: ${({ theme }) => theme.spaces[6]};
        height: ${({ theme }) => theme.spaces[6]};
        border-width: ${({ theme }) => `calc(${theme.spaces[10]} / 10)`};
        border-style: solid;
        border-color: ${({ theme, loaderColor }) =>
          hexToRgba(theme.colors[loaderColor], 0.2)};
        border-left-color: ${({ theme, loaderColor }) =>
          theme.colors[loaderColor]};
        margin: auto;
        animation: button-loader-animation 1.1s infinite linear;
        border-radius: ${({ theme }) => theme.radii['full']};
        transform: translateZ(0) translateX(-50%) translateY(-50%);
        content: '';
      }

      @keyframes button-loader-animation {
        0% {
          transform: translateZ(0) translateX(-50%) translateY(-50%)
            rotate(0deg);
        }
        100% {
          transform: translateZ(0) translateX(-50%) translateY(-50%)
            rotate(360deg);
        }
      }
    `}
`;

Button.defaultProps = {
  as: null,
  backgroundColor: 'blue-600',
  color: 'white-100',
  variant: 'contained',
  styled: true,
  hoverEffect: true,
  focusEffect: true,
  clickable: true,
  fontSize: 'base',
  fontSizeMd: null,
  padding: 4,
  paddingX: null,
  paddingY: null,
  squared: false,
  verticalSpacing: null,
  horizontalSpacing: null,
  borderTopRightRadius: null,
  borderBottomRightRadius: null,
  borderBottomLeftRadius: null,
  borderTopLeftRadius: null,
  showLoader: false,
  disabled: false,
  loaderColor: 'white-100',
  borderRadius: 'std',
  allowPointerEvents: true,
};

export default Button;
