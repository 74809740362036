/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled from 'styled-components';

const StyledPopup = styled.div<IStyledPopupProps>`
  position: fixed;
  z-index: ${(p) => p.zIndex};
  top: ${(p) => p.top}px;
  left: ${(p) => p.left}px;
  width: ${({ size }) => (size === 'base' ? '1120px' : '600px')};
  border: 1px solid ${({ theme }) => theme.colors['grey-300']};
  background: ${({ theme }) => theme.colors['white-100']};
  border-radius: ${({ theme }) => theme.radii['std']};
  box-shadow: ${({ theme }) => theme.shadows['xl']};
  ${({ size }) => size === 'sm' && 'max-height: 455px'};
`;

StyledPopup.defaultProps = {
  size: 'base',
};

export default StyledPopup;
