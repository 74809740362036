/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { createContext } from 'react';
import Draggable from 'react-draggable';
import CloseIcon from '@material-ui/icons/Close';

import StyledPopup from './style';

import Button from 'atoms/Button';
import Box from 'atoms/Box';

export const PopupContext = createContext('');

const Popup = ({
  id,
  positionY,
  positionX,
  zIndex,
  onClose,
  onSelect,
  size,
  children,
}: IPopupProps): JSX.Element => (
  <Draggable
    bounds="parent"
    defaultClassName="draggable"
    onMouseDown={onSelect}
    handle=".draggable-handle"
  >
    <StyledPopup top={positionY} left={positionX} zIndex={zIndex} size={size}>
      <Box
        position="absolute"
        exactTop={size !== 'sm' ? '0.65rem' : null}
        top={size === 'sm' ? 3 : null}
        right="5"
        zIndex="1"
        display="flex"
      >
        <Button
          onClick={onClose}
          type="button"
          squared
          padding="1"
          backgroundColor="grey-100"
          color="black-900"
          borderRadius="md"
        >
          <CloseIcon />
        </Button>
      </Box>

      <PopupContext.Provider value={id}>{children}</PopupContext.Provider>
    </StyledPopup>
  </Draggable>
);

export default Popup;
