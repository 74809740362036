/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState } from 'react';

import StyledTextInput from './style';
import StyledTextInputContainer from './TextInputContainer';

const TextInput = ({
  type,
  borderColor,
  marginBottom,
  padding,
  flex,
  Icon,
  innerRef,
  ...props
}: ITextInputProps): JSX.Element => {
  if (!(type === 'text' || type === 'password' || type === 'email')) {
    console.error('TextInput component instanced with type other than text');
    return null;
  }

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  if (Icon) {
    return (
      <StyledTextInputContainer
        borderColor={borderColor}
        marginBottom={marginBottom}
        padding={padding}
        flex={flex}
        focused={isFocused}
      >
        <Icon />

        <StyledTextInput
          type={type}
          onFocus={handleFocus}
          onBlur={handleBlur}
          borderColor="transparent"
          marginBottom="0"
          padding="0"
          marginLeft="2"
          ref={innerRef}
          {...props}
        />
      </StyledTextInputContainer>
    );
  }

  return (
    <StyledTextInput
      type={type}
      onFocus={handleFocus}
      onBlur={handleBlur}
      borderColor={borderColor}
      marginBottom={marginBottom}
      padding={padding}
      flex={flex}
      ref={innerRef}
      focused={isFocused}
      {...props}
    />
  );
};

export default TextInput;
