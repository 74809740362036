/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useMemo } from 'react';

import { StyledSidebar, StyledNav, StyledUl } from './style';

import NavItem from './NavItem';
import Link from 'atoms/Link';
import Typography from 'atoms/Typography';
import navRoutes from 'utils/routes/navRoutes';
import Hr from 'atoms/Hr';
import Box from 'atoms/Box';

const Sidebar = ({
  currentRoute,
  appName,
  onPressNavItem,
  isSidebarCollapsed,
}: ISidebarProps): JSX.Element => {
  const homeLink = useMemo(
    () => (
      <Link
        href="https://www.sip3.io"
        hoverEffect={false}
        color="black-900"
        display="block"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isSidebarCollapsed ? 'center' : 'space-between'}
        >
          <Box width="50px" minWidth="50px" minHeight="50px" height="50px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 284 284"
              width="50"
              height="50"
            >
              <path
                fill="#092C40"
                d="M14.4 186.1v10.7c0 20.9 7.7 31.9 22.8 31.9 15.4 0 22.6-9.4 22.6-36.6 0-24-5.3-34.4-25.5-50C13.6 126 3.5 116.7 3.5 88.2c0-23.9 7.1-46 34.4-45.7 24.1.2 33.2 15.1 33.2 42.2v8.1H56.9v-7.7c0-17.9-3.7-29.9-19.3-29.9-15.8 0-19.8 13.9-19.8 30.7 0 21 4.3 28 22.6 42.1 25.5 19.6 33.8 30.2 33.8 62.2 0 27.1-6.5 51.1-36.9 51.1C9.1 241.3.2 222.4.2 196.8v-10.9h14.2v.2zm97.3-141.2V239H97.5V44.9h14.2z"
              />
              <path
                fill="#5AADDF"
                d="M142.2 44.9h30.2c27.5 0 39.8 14.2 39.8 43.5v12.1c0 27.2-6.8 48.9-41.1 48.9h-14.7V239h-14.2V44.9zm14.3 92h13.9c24.3 0 27.5-15.3 27.5-36.3v-11c0-16.8-4-32-26.3-32h-15v79.3h-.1z"
              />
              <path
                fill="#F93841"
                d="M227.3 123.3v-2.6c0-11.9 2.7-32.8 26.8-32.8 19 0 26.1 12.1 26.1 32.2v7.9c0 17.4-5.2 24.5-13.5 27.8v.2c11 3.5 17.2 12.3 17.2 31.6V201c0 23.3-6.7 39.8-30 39.8-21.5 0-29.2-13.7-29.2-32.7v-3.9h10.9v3.9c0 13.1 5.3 22.8 18.4 22.8 13.2 0 18.4-8.5 18.4-29v-14.2c0-20.9-7.6-26.4-21.7-26.4h-6.2v-9.9h6.2c11.8 0 18.4-3.9 18.4-22.5v-8.6c0-12.9-3.1-22.6-15.4-22.6-12.6 0-15.8 10.8-15.8 22v3.4h-10.6v.2z"
              />
            </svg>
          </Box>

          {isSidebarCollapsed ? null : (
            <Typography as="span" textAlign="center" className="flex-1">
              {appName}
            </Typography>
          )}
        </Box>
      </Link>
    ),
    [isSidebarCollapsed]
  );

  const navItems = useMemo(
    () =>
      navRoutes.map((route, idx) => (
        <NavItem
          key={idx}
          route={route}
          currentRoute={currentRoute}
          onPressNavItem={onPressNavItem}
          isSidebarCollapsed={isSidebarCollapsed}
        />
      )),
    [navRoutes, currentRoute, isSidebarCollapsed]
  );

  return (
    <StyledSidebar isSidebarCollapsed={isSidebarCollapsed}>
      {homeLink}

      <Hr />

      <StyledNav>
        <StyledUl>{navItems}</StyledUl>
      </StyledNav>
    </StyledSidebar>
  );
};

export default React.memo(Sidebar);
