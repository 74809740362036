/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  APPROXIMATE_POPUP_HEIGHT,
  APPROXIMATE_POPUP_WIDTH,
  DELTA_X,
  DELTA_Y,
} from '../constants';

export default class PositionResolver {
  private _popupsStack: (string | null)[] = [];

  private static _resolveXY(n: number): {
    positionX: number;
    positionY: number;
  } {
    let positionX = Math.max(
      0,
      (window.innerWidth - APPROXIMATE_POPUP_WIDTH) / 2 - DELTA_X
    );
    let positionY = Math.max(
      0,
      (window.innerHeight - APPROXIMATE_POPUP_HEIGHT) / 2 - DELTA_Y
    );

    for (; n > 0; n--) {
      positionX += DELTA_X;
      positionY += DELTA_Y;

      if (
        positionX > window.innerWidth - APPROXIMATE_POPUP_WIDTH ||
        positionY > window.innerHeight - APPROXIMATE_POPUP_HEIGHT
      ) {
        while (positionX > 0 && positionY > 0) {
          positionX -= DELTA_X;
          positionY -= DELTA_Y;
        }
        positionX += DELTA_X;
        positionY += DELTA_Y;
      }
    }
    return { positionX, positionY };
  }

  public reservePosition(popupId: string): {
    positionX: number;
    positionY: number;
  } {
    const n = this._popupsStack.length;
    const { positionX, positionY } = PositionResolver._resolveXY(n);
    this._popupsStack.push(popupId);
    return { positionX, positionY };
  }

  public freePosition(popupId: string): void {
    const idx = this._popupsStack.indexOf(popupId);
    if (idx !== -1) {
      this._popupsStack[idx] = null;
    }
    while (
      this._popupsStack.length > 0 &&
      this._popupsStack[this._popupsStack.length - 1] === null
    ) {
      this._popupsStack.length--;
    }
  }

  public freeAll(): void {
    this._popupsStack = [];
  }
}
