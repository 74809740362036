/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { css } from 'styled-components';

const getStylesForScrollbar = ({
  scrollbarColor,
  backgroundColor,
  isAlwaysVisible = false,
}: {
  scrollbarColor: keyof IThemeColors;
  backgroundColor: keyof IThemeColors;
  isAlwaysVisible?: boolean;
}) => css`
  scrollbar-color: ${({ theme }) =>
      isAlwaysVisible ? theme.colors[scrollbarColor] : 'transparent'}
    ${({ theme }) => theme.colors[backgroundColor]};

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors[backgroundColor]};
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid
      ${({ theme }) =>
        isAlwaysVisible ? theme.colors[backgroundColor] : 'transparent'};
    ${({ theme }) =>
      isAlwaysVisible && `background-color: ${theme.colors[scrollbarColor]}`};
  }

  ${!isAlwaysVisible &&
  css`
    &:hover {
      scrollbar-color: ${({ theme }) =>
        `${theme.colors[scrollbarColor]} ${theme.colors[backgroundColor]}`};

      &::-webkit-scrollbar-thumb {
        border-color: ${({ theme }) => theme.colors[backgroundColor]};
        background-color: ${({ theme }) => theme.colors[scrollbarColor]};
      }
    }
  `}
`;

export default getStylesForScrollbar;
