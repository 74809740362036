/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 *  Base class allowing to keep and update model,
 *  and subscribe on model changes
 */
export default class BaseObservable<M> {
  protected _model: M;
  private _subscriptions: ((m: M) => unknown)[] = [];

  constructor(model: M) {
    this._model = model;
  }

  public getModel(): M {
    return this._model;
  }

  public subscribeToUpdates(callback: (m: M) => unknown): IDisposable {
    this._subscriptions.push(callback);

    return {
      dispose: () => {
        this.unsubscribeFromUpdates(callback);
      },
    };
  }

  public subscribeToUpdatesAndNotify(callback: (m: M) => unknown): IDisposable {
    const subscription: IDisposable = this.subscribeToUpdates(callback);
    callback(this.getModel());
    return subscription;
  }

  public unsubscribeFromUpdates(callback: (m: M) => unknown): void {
    const idx = this._subscriptions.indexOf(callback);
    if (idx !== -1) {
      this._subscriptions.splice(idx, 1);
    }
  }

  protected _updateModel(partialModel: Partial<M>): void {
    this._model = {
      // Spread types may only be created from object types.
      ...this._model,
      ...partialModel,
    };

    // TODO: check changed
    this._notifySubscriptions();
  }

  protected _notifySubscriptions(): void {
    const subscriptions = this._subscriptions.slice(0);
    const model = this._model;

    for (const s of subscriptions) {
      if (this._subscriptions.indexOf(s) !== -1) {
        try {
          s(model);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }
}
