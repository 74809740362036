/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled, { createGlobalStyle } from 'styled-components';

import getStylesForScrollbar from 'utils/getStylesForScrollbar';
import { RESET_GLOBAL_SCROLLBAR_COLORS } from 'constants/classNames';

const StyledApp = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  @supports (-moz-appearance: none) {
    height: 100vh;
    max-height: 100vh;
  }
`;

const GlobalStyle = createGlobalStyle<{ theme: ITheme }>`
  * {
    -ms-overflow-style: -ms-autohiding-scrollbar;

    scrollbar-width: auto;

    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }
  }

  *:not(.${RESET_GLOBAL_SCROLLBAR_COLORS}) {
    ${getStylesForScrollbar({
      scrollbarColor: 'grey-150',
      backgroundColor: 'white-100',
      isAlwaysVisible: false,
    })}
  }
`;

export { GlobalStyle };
export default StyledApp;
