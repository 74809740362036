/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createElement, useState } from 'react';

import { StyledMainLayout, StyledMainArea } from './style';

import Sidebar from './Sidebar';
import Header from './Header';
import Page from './Page';
import Footer from './Footer';
import Box from 'atoms/Box';
import { RESET_GLOBAL_SCROLLBAR_COLORS } from 'constants/classNames';
import { SIDEBAR_COLLAPSED_STATE_KEY_IN_LOCAL_STORAGE } from 'constants/localStorageKeys';

const sidebarStateMapping = {
  true: true,
  false: false,
};

const MainLayout = ({
  currentRoute,
  appName,
  page,
  onPressNavItem,
}: IMainLayoutProps): JSX.Element => {
  const initialSidebarState =
    sidebarStateMapping[
      localStorage.getItem(SIDEBAR_COLLAPSED_STATE_KEY_IN_LOCAL_STORAGE)
    ] || false;
  const [isSidebarCollapsed, setIsSidebarCollapsed] =
    useState(initialSidebarState);

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevIsSidebarCollapsed) => {
      localStorage.setItem(
        SIDEBAR_COLLAPSED_STATE_KEY_IN_LOCAL_STORAGE,
        String(!prevIsSidebarCollapsed)
      );

      return !prevIsSidebarCollapsed;
    });
  };

  return (
    <StyledMainLayout>
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="stretch"
        justifyContent="space-between"
        flex="1"
        firefoxOnlyHeight="100vh"
        firefoxOnlyMaxHeight="100vh"
      >
        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Sidebar
            currentRoute={currentRoute}
            appName={appName}
            onPressNavItem={onPressNavItem}
            isSidebarCollapsed={isSidebarCollapsed}
          />
        </Box>

        <Box
          display="flex"
          alignItems="stretch"
          justifyContent="space-between"
          flexDirection="column"
          flex="1"
        >
          <StyledMainArea className={RESET_GLOBAL_SCROLLBAR_COLORS}>
            <Header
              toggleSidebar={toggleSidebar}
              isSidebarCollapsed={isSidebarCollapsed}
            />

            <Page>{page ? createElement(page) : null}</Page>

            <Footer />
          </StyledMainArea>
        </Box>
      </Box>
    </StyledMainLayout>
  );
};

export default MainLayout;
