/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled, { css } from 'styled-components';

const getResolvedColor = ({
  theme,
  color,
  colorExact,
}: IStyledTypographyProps) => {
  if (colorExact) return colorExact;

  if (color) {
    return theme.colors[color];
  }

  return 'inherit';
};

const StyledTypography = styled.div<IStyledTypographyProps>`
  color: ${({ theme, color, colorExact }) =>
    getResolvedColor({ theme, color, colorExact })};
  font-size: ${({ theme, fontSize, as }) =>
    fontSize
      ? theme.fontSizes[fontSize]
      : theme.elementsToFontSizesMapping[as]};
  font-weight: 500;
  line-height: ${({ lineHeight }) => lineHeight};
  ${({ theme, marginTop, marginBottom, marginLeft, as }) =>
    as !== 'span' &&
    as !== 'small' &&
    css`
      ${marginTop && `margin-top: ${theme.spaces[marginTop]}`};
      ${marginBottom && `margin-bottom: ${theme.spaces[marginBottom]}`};
      ${marginLeft && `margin-left: ${theme.spaces[marginLeft]}`};
    `};
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace}`};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: 968px) {
    font-size: ${({ theme, fontSizeMd }) => theme.fontSizes[fontSizeMd]};
  }
`;

StyledTypography.defaultProps = {
  as: 'p',
  fontSize: null,
  fontSizeMd: null,
  lineHeight: '1.2',
  textAlign: 'left',
  marginBottom: 3,
  marginTop: null,
  marginLeft: null,
  whiteSpace: null,
};

export default StyledTypography;
