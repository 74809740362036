/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useEffect, useState } from 'react';
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import cn from 'classnames';

import StyledAuthScreen, { BodyStyle } from './style';

import Typography from 'atoms/Typography';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import AuthFooter from './AuthFooter';

import AppStateInteractor from 'interactors/AppStateInteractor';
import AuthenticationInteractor from 'interactors/AuthenticationInteractor';
import Box from 'atoms/Box';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
    },
    item: {
      width: '100%',
    },
    upperItem: {
      flex: 10,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',
    },
    lowerItem: {
      flex: 1,
    },
  })
);

const AuthScreen = (): JSX.Element => {
  const appStateInteractorInstance = AppStateInteractor.getInstance();
  const appState = appStateInteractorInstance.getModel();
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [signup, setSignUp] = useState(
    appState.currentRoute?.path === '/signup' || false
  );

  useEffect(() => {
    appStateInteractorInstance.subscribeToUpdates(onAppStateChanged);
    const newAppState = appStateInteractorInstance.getModel();
    setSignUp(newAppState.currentRoute?.path === '/signup' || false);

    return () => {
      appStateInteractorInstance.unsubscribeFromUpdates(onAppStateChanged);
    };
  }, []);

  const onAppStateChanged = (newAppState: IAppState) => {
    setSignUp(newAppState.currentRoute?.path === '/signup' || false);
  };

  const onSignIn = async (username: string, password: string) => {
    setIsLoading(true);
    setError(null);
    try {
      await AuthenticationInteractor.getInstance().signIn(username, password);
    } catch (err) {
      setIsLoading(false);
      setIsLoading(err.toString());
    }
  };

  return (
    <StyledAuthScreen>
      <BodyStyle />
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        className={classes.container}
      >
        <Grid
          item
          lg={5}
          md={7}
          sm={8}
          xs={12}
          className={cn(classes.upperItem, classes.item)}
        >
          <Typography
            as="h1"
            fontSize="4xl"
            textAlign="center"
            marginBottom="12"
            marginTop="6"
          >
            Welcome to SIP3.IO
          </Typography>

          <Box
            display="flex"
            width="100%"
            padding="12"
            backgroundColor="white-100"
            borderRadius="std"
            boxShadow="md"
            color="blue-900"
          >
            {signup ? (
              <SignUpForm isLoading={isLoading} />
            ) : (
              <SignInForm
                isLoading={isLoading}
                onSignIn={onSignIn}
                error={error}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={11} className={cn(classes.item, classes.lowerItem)}>
          <AuthFooter />
        </Grid>
      </Grid>
    </StyledAuthScreen>
  );
};

export default AuthScreen;
