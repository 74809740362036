/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';

import StyledFooter from './style';

import Typography from 'atoms/Typography';
import Link from 'atoms/Link';

const Footer = () => (
  <StyledFooter>
    <Typography as="small" fontSize="xs">
      Version: {process.env.npm_package_version || 'not provided'}
      &nbsp;
      <strong>Copyright &copy; 2018 - {new Date().getFullYear()}</strong>
      &nbsp;
      <Link
        href="https://sip3.io"
        fontSize="sm"
        target="_blank"
        rel="noopener noreferrer"
      >
        <strong>SIP3</strong>.IO, Corp.
      </Link>
      &nbsp; All rights reserved.
    </Typography>
  </StyledFooter>
);

export default React.memo(Footer);
