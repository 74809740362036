/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { API_PREFIX } from 'src/constants';
import { IS_DEVELOPMENT } from 'constants/buildModes';

const CHECK_URL = API_PREFIX + '/attributes';

export default class AuthenticationService {
  public async check(): Promise<boolean> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    if (IS_DEVELOPMENT) {
      headers.append('x-sip3-dev', 'true');
    }

    const response = await fetch(CHECK_URL, {
      method: 'GET',
      credentials: 'include',
      headers,
    });
    if (response.status === 401 || response.status === 403) {
      return false;
    }
    // 404?
    return true;
  }

  public async signIn(username: string, password: string): Promise<boolean> {
    const headers = new Headers();
    // headers.append('Content-Type', 'application/json');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    if (IS_DEVELOPMENT) {
      headers.append('x-sip3-dev', 'true');
    }

    // let body = JSON.stringify({username, password})
    let body = 'username=' + encodeURIComponent(username);
    if (password) {
      body += '&password=' + encodeURIComponent(password);
    }

    const response = await fetch(API_PREFIX + '/login', {
      method: 'POST',
      credentials: 'include',
      headers,
      body,
    });

    try {
      await response.text();
    } catch (err) {
      console.error(err);
    }

    // TODO: different kinds of error

    if (response.status === 401 || response.status === 403) {
      return false;
    }

    if (response.status >= 500) {
      throw new Error('Internal Server Error');
    }

    return true;
  }

  public async signOut(): Promise<boolean> {
    const response = await fetch(API_PREFIX + '/logout', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: null,
    });
    return response.status !== 401;
  }

  //
  // singleton
  //
  private static _instance: AuthenticationService | null;

  public static getInstance(): AuthenticationService {
    if (!AuthenticationService._instance) {
      AuthenticationService._instance = new AuthenticationService();
    }
    return AuthenticationService._instance;
  }
}
