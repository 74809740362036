/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled from 'styled-components';
import getStylesForScrollbar from 'utils/getStylesForScrollbar';

const StyledMainLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  background-color: ${({ theme }) => theme.colors['blue-200']};

  @supports (-moz-appearance: none) {
    height: 100vh;
    max-height: 100vh;
  }
`;

const StyledMainArea = styled.div`
  position: relative;
  display: flex;
  overflow: auto;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  ${getStylesForScrollbar({
    scrollbarColor: 'grey-150',
    backgroundColor: 'white-100',
    isAlwaysVisible: true,
  })}
`;

export { StyledMainLayout, StyledMainArea };
