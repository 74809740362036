/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useState } from 'react';

import {
  StyledSignUpFormContainer,
  StyledSignUpFormTitleContainer,
  StyledSignUpForm,
} from './style';

import Typography from 'atoms/Typography';
import Hr from 'atoms/Hr';
import Link from 'atoms/Link';
import Button from 'atoms/Button';
import TextInput from 'atoms/inputs/TextInput';
import Box from 'atoms/Box';

import sip3InCircleLogo from 'assets/svg/logo/sip3-in-circle.svg';

const SignUpForm = ({ isLoading }: ISignUpForm): JSX.Element => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [domain, setDomain] = useState('');

  return (
    <StyledSignUpFormContainer>
      <StyledSignUpFormTitleContainer>
        <img src={sip3InCircleLogo} alt="sip3 logo" width="75" height="75" />

        <Typography as="h4" marginBottom="0">
          Create account
        </Typography>
      </StyledSignUpFormTitleContainer>

      <Hr space={10} />

      <StyledSignUpForm role="login" name="signupForm" noValidate={false}>
        <TextInput
          type="text"
          id="rc-new-first-name"
          name="firstName"
          placeholder="First Name"
          required={true}
          autoFocus={true}
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
          autoComplete="given-name"
        />

        <TextInput
          type="text"
          id="rc-new-last-name"
          name="lastName"
          placeholder="Last Name"
          required={true}
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
          autoComplete="family-name"
        />

        <TextInput
          type="email"
          id="rc-new-email"
          name="email"
          placeholder="Email address"
          required={true}
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          autoComplete="email"
        />

        <TextInput
          type="password"
          id="rc-new-password"
          name="password"
          placeholder="Password"
          minLength={5}
          required={true}
          value={password}
          onChange={(e) => setPassword(e.currentTarget.value)}
          autoComplete="new-password"
        />

        <TextInput
          type="text"
          id="rc-new-domain"
          name="domain"
          placeholder="Domain Name"
          maxLength={255}
          pattern="^[a-zA-Z0-9-]+$"
          required={true}
          value={domain}
          onChange={(e) => setDomain(e.currentTarget.value)}
        />

        <Box marginTop="4" marginBottom="4">
          <Button
            id="signup-button"
            type="submit"
            showLoader={isLoading}
            disabled={
              isLoading ||
              firstName === '' ||
              lastName === '' ||
              email === '' ||
              password === '' ||
              domain === ''
            }
          >
            Create Account
          </Button>
        </Box>

        <Typography as="small" marginTop="4">
          Already have an account? &nbsp;
          <Link href="#/" fontSize="sm">
            Sign in
          </Link>
        </Typography>
      </StyledSignUpForm>
    </StyledSignUpFormContainer>
  );
};

export default SignUpForm;
