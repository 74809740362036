/*
 * Copyright 2018-2021 SIP3.IO, Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import styled from 'styled-components';

const StyledFooter = styled.footer`
  display: flex;
  height: ${({ theme }) => theme.spaces[12]};
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.gridGutters[1]} ${({ theme }) => theme.gridGutters[2]};
  background-color: ${({ theme }) => theme.colors['white-100']};
`;

export default StyledFooter;
